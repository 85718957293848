<template>
  <div v-if="payments.length > 0">
    <div class="flex justify-between">
      <app-section-title
        :title="t('traveller_folders.pages.show.sections.payments')"
        class="mb-3.5 mt-6"
      />
    </div>

    <div ref="appTableContainerRef">
      <app-table
        :headers="headers"
        :data="tableData"
        :loading="paymentsLoading"
      />
    </div>

    <app-pagination
      :links="links"
      :change-page-callback="handleChangePage"
      :element-to-scroll-to="appTableContainerRef"
      class="mt-5"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { upperCase } from 'lodash'

import {
  fetchPayments as fetchApiPayments,
  receiptPayment,
} from '@shared/http/api'
import { getAmountWithCurrency, getPayable } from '@shared/helpers/payment'
import usePdf from '@shared/hooks/pdf'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import PaymentTableActions from '@extranet/components/resources/payment/PaymentTableActions.vue'

const props = defineProps({
  // Butler ID
  butlerId: {
    type: Number,
    default: null,
  },
})

const { t } = useI18n()

// ---------- PAYMENTS ----------

const payments = ref([])
const paymentsLoading = ref(true)

const headers = [
  { label: t('validation.attributes.net_amount') },
  { label: t('validation.attributes.payment_service') },
  { label: t('validation.attributes.payment_type') },
  { label: t('validation.attributes.payable') },
  { label: t('validation.attributes.actions') },
]

const tableData = computed(() => (
  payments?.value?.map((payment) => ({
    net_amount: getAmountWithCurrency(payment.resource.attributes.net_amount, payment.resource.attributes.currency),
    payment_service: t(`enums.PaymentServiceEnum.${upperCase(payment.resource.attributes.payment_service)}`),
    payment_type: t(`enums.PaymentTypeEnum.${upperCase(payment.resource.attributes.payment_type)}`),
    payable: getPayable(payment),
    actions: {
      component: PaymentTableActions,
      props: {
        resource: payment.resource,
        receiptCallback: () => handleReceipt(payment.resource.id),
      },
    },
  }))
))

function fetchPayments(nextPage = false) {
  if (nextPage === false) {
    paymentsLoading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'mode': 'payer',
    'butler_id': props.butlerId,
  }

  fetchApiPayments(params)
    .then((response) => {
      const oldPayments = payments.value
      const newPayments = response.data.data?.map((payment) => ({
        resource: payment,
      })) ?? []

      if (nextPage) {
        // Add new results after the old ones
        payments.value = [...oldPayments, ...newPayments]
      } else {
        // Replace the old results with the new ones
        payments.value = newPayments
      }

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      nextPageFetching.value = false
      paymentsLoading.value = false
    })
}

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const nextPageFetching = ref(false)
const links = ref([])
const page = ref(1)

function handleChangePage(value) {
  page.value = value
  fetchPayments()
}

// ---------- RECEIPT ----------

const { downloadPdf } = usePdf()

function handleReceipt(value) {
  receiptPayment(value)
    .then((response) => {
      downloadPdf(response.data, 'receipt')
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPayments()
})
</script>
